import PropTypes from 'prop-types';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import IconButton from 'components/common/IconButton';

const ProductVideo = props => {
  const { formValues, setFormValues } = props;
  const uploadRef = React.useRef();

  const setState = event => {
    // if file format is not video return
    if (!event.target.files[0].type.includes('video')) {
      toast.error('Please select a video file', {
        theme: 'colored'
      });
      return;
    }

    const file = Object.assign(event.target.files[0], {
      preview: URL.createObjectURL(event.target.files[0])
    });
    // if file size > 300MB return
    if (file.size > 314572800) {
      toast.error('Video size must be less then 300MB', {
        theme: 'colored'
      });
      return;
    }
    if (!file) return;
    setFormValues({ ...formValues, video: file });
  };

  const handleClick = e => {
    e.preventDefault();
    uploadRef.current.click();
  };

  return (
    <Card className="mb-3">
      <Card.Header>Video</Card.Header>
      <Card.Body>
        <Row>
          <Col xs={12} lg={3}>
            <button className="upload-button" onClick={handleClick}>
              <img src={cloudUpload} alt="" width={25} className="me-2" />
              <p className="fs-0 mb-0 text-700">Upload Video</p>
            </button>
            <input
              type="file"
              accept="video/*"
              style={{ display: 'none' }}
              ref={uploadRef}
              onChange={setState}
            />
          </Col>
          <Col xs={12} lg={6}>
            {(formValues.video || formValues.product_video) && (
              <div
                style={{
                  position: 'relative'
                }}
              >
                <video
                  src={formValues.video?.preview ?? formValues.product_video}
                  controls
                  className="w-100 rounded"
                />
                <IconButton
                  onClick={() =>
                    setFormValues({
                      ...formValues,
                      video: null,
                      product_video: null
                    })
                  }
                  variant="falcon-danger"
                  size="sm"
                  icon="trash"
                  transform="shrink-3"
                  style={{
                    position: 'absolute',
                    top: 0,
                    right: 0
                  }}
                />
              </div>
            )}
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer></Card.Footer>
    </Card>
  );
};

ProductVideo.propTypes = {
  formValues: PropTypes.object,
  setFormValues: PropTypes.func
};

export default ProductVideo;
